<template>
	<v-text-field
		:value="value"
		v-on="$listeners"
		background-color="grey lighten-4"
		flat
		dense
		light
		solo
		:placeholder="placeholder"
		:hint="hint"
		:persistent-hint="persistentHint"
		class="caption font-weight-bold form-field-text"
	></v-text-field>
</template>

<script>
export default {
	name: "FormFieldText",
	props: {
		value: {
			type: String
		},
		placeholder: {
			type: String
		},
		hint: {
			type: String
		},
		persistentHint: {
			type: Boolean,
			default: false
		}
	}
};
</script>
<style lang="scss" scoped>
</style>
