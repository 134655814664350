<template>
	<v-row class="pl-2">
		<v-col
			cols="1"
			md="2
            "
			sm="4"
			align="center"
		>
			<v-card :color="backgroundColor" class="card-icon pt-5 pb-5" dark>
				<!-- <v-icon x-large>mdi-account-hard-hat</v-icon> -->
				<slot></slot>
			</v-card>
		</v-col>
		<v-spacer></v-spacer>
	</v-row>
</template>

<script>
export default {
	name: "UtilityUIFormIcon",
	props: {
		backgroundColor: {
			type: String,
			default: "blue-grey darken-4"
		}
	}
};
</script>

<style scoped>
.card-icon {
	margin-top: -35px;
	padding-top: 35px;
	padding-bottom: 35px;
}
.v-input__slot {
	margin-bottom: 0 !important;
}
</style>