<template>
	<v-col cols="12">
		<v-card elevation="0" class="pl-5 pr-5 mt-2" color="transparent">
			<v-row>
				<v-col>
					<v-card elevation="0" class="mb-5" color="transparent">
						<v-card-title class="button font-weight-medium pa-0 blue-grey--text text--darken-4">
							LoadBoard
							<span class="ml-1 warning--text font-weight-bold">Add Crew</span>
						</v-card-title>
						<v-card-title class="mt-3 pb-0">
							<v-spacer></v-spacer>
							<v-btn class="ma-0 caption font-weight-bold mr-2" tile dark color="warning" width="100">Save</v-btn>
							<v-btn class="ma-0 caption font-weight-bold mr-2" tile dark color="warning" width="100">Add New</v-btn>
							<v-btn class="ma-0 caption font-weight-bold" tile dark color="warning" width="100">Cancel</v-btn>
						</v-card-title>
					</v-card>
				</v-col>
			</v-row>
			<v-card elevation="1" class="pl-0 pr-0 pt-2 pb-10">
				<v-container fill-height>
					<!-- Page Icon -->
					<PageIcon :background-color="pageIconBackgroundColor">
						<v-icon x-large>mdi-account-hard-hat</v-icon>
					</PageIcon>

					<AddTruckCrew ref="loadboardTruckCrew" :crewInfo="crewInfo" :crewInfoLoopedList="crewFields" :emergencyContact="contactFields"></AddTruckCrew>
				</v-container>
			</v-card>
		</v-card>
	</v-col>
</template>

<script>
// COMPONENTS
const AddTruckCrew = () => import(/* webpackChunkName: 'loadboard add driver'*/ "Components/loadboard/LoadboardAddCrew");
import PageIcon from "Components/UtilityUI/UtilityUIPageIcon";
export default {
	components: {
		AddTruckCrew,
		PageIcon
	},
	data() {
		return {
			pageIconBackgroundColor: "indigo darken-2",
			dateMenu: [],
			crewInfo: {
				firstName: "",
				lastName: "",
				type: "",
				street: "",
				city: "",
				state: "",
				zipcode: "",
				phone: "",
				alternatePhone: "",
				fax: "",
				email: "",
				paymentType: "",
				licenseNumber: "",
				licenseExpiration: null,
				licenseState: "",
				medicalCardRenewal: null,
				hireDate: null,
				terminationDate: null,
				hourlyRate: "",
				FixRate: "",
				emergencyContact1: {
					contactName: "",
					contactPhone: "",
					contact: "",
					contactStreet: "",
					contactCity: "",
					contactState: "",
					contactZipCode: ""
				},
				emergencyContact2: {
					contactName: "",
					contactPhone: "",
					contact: "",
					contactStreet: "",
					contactCity: "",
					contactState: "",
					contactZipCode: ""
				}
			},
			crewFields: [
				{
					id: "firstName",
					name: "First Name",
					type: "text",
					placeholder: "Firstname"
				},
				{
					id: "lastName",
					name: "Last Name",
					type: "text",
					placeholder: "Lastname"
				},
				{
					id: "type",
					name: "Type",
					type: "text",
					placeholder: "Laborer/Driver"
				},
				{
					id: "street",
					name: "Street",
					type: "text",
					placeholder: "Optional"
				},
				{
					id: "city",
					name: "City",
					type: "text",
					placeholder: "City"
				},
				{
					id: "state",
					name: "State/Province",
					type: "select",
					options: [
						{
							name: "California",
							id: "CA"
						},
						{
							name: "Nevada",
							id: "NV"
						}
					],
					placeholder: "Select state"
				},
				{
					id: "zipcode",
					name: "Zip Code",
					type: "text",
					placeholder: "Optional"
				},
				{
					id: "phone",
					name: "Phone",
					type: "text",
					placeholder: "Optional"
				},
				{
					id: "alternatePhone",
					name: "Alternate Phone",
					type: "text",
					placeholder: "Optional"
				},
				{
					id: "fax",
					name: "Fax",
					type: "text",
					placeholder: "Optional"
				},
				{
					id: "email",
					name: "Email",
					type: "text",
					placeholder: "Optional"
				},
				{
					id: "paymentType",
					name: "Default Payment Type",
					type: "select",
					options: [
						{
							name: "Manual Pay",
							id: "manualPay"
						},
						{
							name: "Credit Card",
							id: "cc"
						}
					],
					placeholder: "Select payment type"
				},
				{
					id: "licenseNumber",
					name: "License Number",
					type: "text",
					placeholder: ""
				},
				{
					id: "licenseExpiration",
					name: "License Expiration",
					type: "date",
					placeholder: "mm/dd/yy",
					icon: "mdi-calendar-month-outline"
				},
				{
					id: "licenseState",
					name: "License Issuing State/Jurisdiction",
					type: "select",
					options: [
						{
							name: "California",
							id: "CA"
						},
						{
							name: "Nevada",
							id: "NV"
						}
					],
					placeholder: "Select state"
				},
				{
					id: "medicalCardRenewal",
					name: "Medical Card Renewal",
					type: "date",
					placeholder: "mm/dd/yy",
					icon: "mdi-calendar-month-outline"
				},
				{
					id: "hireDate",
					name: "Hire Date",
					type: "date",
					placeholder: "mm/dd/yy",
					icon: "mdi-calendar-month-outline"
				},
				{
					id: "terminationDate",
					name: "Termination Date",
					type: "date",
					placeholder: "mm/dd/yy",
					icon: "mdi-calendar-month-outline"
				},
				{
					id: "hourlyRate",
					name: "Hourly Rate",
					type: "text",
					placeholder: ""
				},
				{
					id: "fixRate",
					name: "Fix Rate",
					type: "text",
					placeholder: ""
				}
			],
			contactFields: [
				{
					id: "contactName",
					name: "Contact Name",
					type: "text",
					placeholder: ""
				},
				{
					id: "contactPhone",
					name: "Contact Phone",
					type: "text",
					placeholder: ""
				},
				{
					id: "contact",
					name: "Contact",
					type: "text",
					placeholder: ""
				},
				{
					id: "contactStreet",
					name: "Contact Street",
					type: "text",
					placeholder: "Optional"
				},
				{
					id: "contactCity",
					name: "Contact City",
					type: "text",
					placeholder: ""
				},
				{
					id: "contactState",
					name: "Contact State",
					type: "select",
					options: [
						{
							name: "California",
							id: "CA"
						},
						{
							name: "Nevada",
							id: "NV"
						}
					],
					placeholder: "Select State"
				},
				{
					id: "contactZipcode",
					name: "Contact Zipcode",
					type: "text",
					placeholder: "Optional"
				}
			]
		};
	}
};
</script>

<style scoped>
</style>
