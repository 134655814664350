<template>
	<v-select
		:items="items"
		v-on="$listeners"
		:item-text="itemText"
		:item-value="itemValue"
		:placeholder="placeholder"
		dense
		flat
		solo-inverted
		class="caption font-weight-bold"
	></v-select>
</template>

<script>
export default {
	name: "FormFieldSelect",
	props: {
		value: {
			type: String,
			required: true
		},
		items: {
			type: Array,
			required: true
		},
		itemText: {
			type: String
		},
		itemValue: {
			type: String
		},
		placeholder: {
			type: String
		}
	}
};
</script>

<style>
</style>