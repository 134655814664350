<template>
	<form action="" class="row">
		<v-row align="start" justify="start">
			<v-col cols="12" md="7" sm="12" class="caption mt-10 pt-8">
				<v-row v-for="field in crewFields" :key="field.name">
					<v-col cols="12" md="4" sm="5" class="text-end blue-grey--text font-weight-bold py-0">
						<div>{{ field.name }}</div>
					</v-col>
					<v-col cols="12" md="8" sm="7" class="py-0">
						<template v-if="field.type === 'text'">
							<FormFieldText v-model="crewInfo[field.id]" :placeholder="field.placeholder"></FormFieldText>
						</template>
						<template v-if="field.type === 'select'">
							<FormFieldSelect v-model="crewInfo[field.id]" :items="field.options" :placeholder="field.placeholder" itemText="name" itemValue="id"></FormFieldSelect>
						</template>
						<template v-if="field.type === 'date'">
							<v-menu
								v-model="dateMenu[crewFields.indexOf(field)]"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="290px"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										:placeholder="field.placeholder"
										:prepend-inner-icon="field.icon"
										v-model="crewInfo[`${field.id}`]"
										solo-inverted
										flat
										readonly
										v-bind="attrs"
										v-on="on"
										:key="field.name"
										class="caption font-weight-bold"
									></v-text-field>
								</template>
								<v-date-picker :key="field.id" v-model="crewInfo[`${field.id}`]" @change="dateMenu[crewFields.indexOf(field)] = false"></v-date-picker>
							</v-menu>
						</template>
					</v-col>
				</v-row>
			</v-col>
			<!-- Emegency Contact -->
			<v-col cols="12" md="5" sm="12" class="caption">
				<!-- Contact 1 -->
				<v-row class="mb-6">
					<v-col>
						<h2 class="pl-8 blue-grey--text text--darken-4">Emergency Contact 1</h2>
					</v-col>
				</v-row>
				<v-row class="pr-5" v-for="contact in contactFields" :key="contact.name">
					<v-col cols="12" md="4" sm="5" class="text-end blue-grey--text font-weight-bold py-0">
						<div class="pt-2">{{ contact.name }}</div>
					</v-col>
					<v-col cols="12" md="8" sm="7" class="py-0">
						<template v-if="contact.type === 'text'">
							<FormFieldText v-model="crewInfo.emergencyContact1[contact.id]" :placeholder="contact.placeholder"></FormFieldText>
						</template>
						<template v-if="contact.type === 'select'">
							<FormFieldSelect
								v-model="crewInfo.emergencyContact1[contact.id]"
								:items="contact.options"
								:placeholder="contact.placeholder"
								itemText="name"
								itemValue="id"
							></FormFieldSelect>
						</template>
					</v-col>
				</v-row>

				<!-- Contact 2 -->
				<v-row class="mb-6 mt-5">
					<v-col>
						<h2 class="pl-8 blue-grey--text text--darken-4">Emergency Contact 2</h2>
					</v-col>
				</v-row>
				<v-row class="pr-5" v-for="contact in contactFields" :key="contact.id">
					<v-col cols="12" md="4" sm="5" class="text-end blue-grey--text font-weight-bold py-0">
						<div class="pt-2">{{ contact.name }}</div>
					</v-col>
					<v-col cols="12" md="8" sm="7" class="py-0">
						<template v-if="contact.type === 'text'">
							<FormFieldText v-model="crewInfo.emergencyContact2[contact.id]" :placeholder="contact.placeholder"></FormFieldText>
						</template>
						<template v-if="contact.type === 'select'">
							<FormFieldSelect
								v-model="crewInfo.emergencyContact2[contact.id]"
								:items="contact.options"
								:placeholder="contact.placeholder"
								itemText="name"
								itemValue="id"
							></FormFieldSelect>
						</template>
					</v-col>
				</v-row>
				<!-- <pre>
								{{ usersInfo }}
							</pre -->
				>
			</v-col>
		</v-row>
	</form>
</template>

<script>
import FormFieldText from "Components/FormFields/FormFieldText";
import FormFieldSelect from "Components/FormFields/FormFieldSelect";
export default {
	name: "LoadboardAddCrew",
	components: {
		FormFieldText,
		FormFieldSelect
	},
	props: {
		crewInfo: {
			type: Object,
			Required: true
		},
		crewInfoLoopedList: {
			type: Array,
			required: true
		},
		emergencyContact: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			dateMenu: [],
			crewFields: [...this.crewInfoLoopedList],
			contactFields: [...this.emergencyContact]
		};
	},
	computed: {
		usersInfo() {
			return this.crewInfo;
		}
	},
	methods: {
		submit() {
			return new Promise((resolve, reject) => {});
		}
	}
};
</script>
<style scoped>
.card-icon {
	margin-top: -50px;
	padding-top: 35px;
	padding-bottom: 35px;
}
.v-input__slot {
	margin-bottom: 0 !important;
}
</style>